<script>
    import RadioButton from "@components/forms/RadioButton.svelte";

    export let sizes;
    export let value;
    export let name = 'size';
    export let controlClass = '';

</script>

<div class="size-selection field is-grouped">
    {#each sizes as size, index}
        <RadioButton 
            {name} 
            id="{name}_{index}"
            value={size.id}
            label={size.value}
            bind:selectedValue={value} 
            isButton 
            on:change
            disabled={size.disabled === true}
            controlClass="mb-1 {controlClass}"
            {...$$restProps}
            />  
    {/each}
</div>

<style>
    .size-selection{
        display: flex;
        flex-wrap: wrap;
    }</style>