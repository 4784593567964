<script>
  import {Phones, Messengers, Emails, getPhone, GetPhone, Social} from '@models/ContactsData'

  export let phones = false;
  export let messengers = false;
  export let emails = false;
  export let combine = false;
  export let social = false;
  export let itemsClass = "";

</script>

{#if phones }
  <div class="phone-component">
      <div class="items {itemsClass}" class:combined={combine}>
          {#each Phones as phone}
            <a href={phone.url} class="contact-line {phone.class}">{getPhone(phone, combine? GetPhone.Code : GetPhone.Short)}</a>
          {/each}
      </div>
      {#if combine}
        <a href={Phones[0].url} class="is-size-5">{Phones[0].text}</a>
      {/if}
  </div>
{/if}

{#if messengers}
  <div class="messenger-component">
      {#each Messengers as messenger}
        {#if messenger.url}
        <a href={messenger.url} class="contact-line {messenger.class} {itemsClass}">{messenger.text}</a>
        {:else}
        <span class="contact-line {messenger.class} {itemsClass}">{messenger.text}</span>
        {/if}
      {/each}
  </div>
{/if}

{#if emails}
  <div class="email-component">
    {#each Emails as email}
        <a href={email.url} class="{itemsClass}"><span class="icon is-size-5"><i class="icon-mail"></i></span> {email.text}</a>
    {/each}
  </div>
{/if}

{#if social}
  <div class="social-component">
    {#each Social as social}
        <a href={social.url} class="contact-line {social.class} {itemsClass}">{social.text}</a>
    {/each}
  </div>
{/if}


<style>
  .phone-component{
    display: flex;
    align-items: center;
  }
  .phone-component .items.combined{
    margin-right: 0.4em;
    font-size: 0.9rem;
  }
  .items{
    white-space: nowrap;
  }</style>