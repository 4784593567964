<script>
    import {t} from '@utils/Formatter'
    import { stores } from '@sapper/app';
    import { Formatter } from '@utils/Formatter';

	const { page} = stores();

    let searchText = '';
    searchText = $page.path === '/search' ? $page.query.text : '';

    function onSearch(e)
    {
        if (searchText === '')
        {
            e.preventDefault();
            return false;
        }

    }

</script>


<form class="field has-addons" method="GET" action={Formatter.url("/search")}>
  <div class="control has-icons-left is-expanded">
    <input id="search"
        name="text"
        type="text"
        class="input is-size-7-mobile"
        placeholder="{$t("common.searchPlaceholder")}"
        autocomplete={"search"},
        bind:value={searchText}
    />
    <span class="icon is-left is-size-7-mobile">
      <i class="icon-search"></i>
    </span>
  </div>
</form>


<style lang="scss">.control {
  max-width: 200px; }
  .control input {
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid #b5b5b5;
    color: white;
    box-shadow: none;
    transition: background-color 0.3s; }
    .control input:focus ~ .icon {
      color: white; }
    .control input:focus {
      background-color: #7e6a8e; }
    .control input::-moz-placeholder {
      color: #dbdbdb; }
    .control input:-ms-input-placeholder {
      color: #dbdbdb; }
    .control input::placeholder {
      color: #dbdbdb; }
  .control .icon {
    color: #dbdbdb; }

form {
  display: flex;
  flex-grow: 1;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0;
  padding-right: 0; }
  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    form {
      padding-right: 0.5rem; } }</style>