<script>
    import { createEventDispatcher } from 'svelte';
    import { t} from "@utils/Formatter";
    import { appStore} from "@models/Stores";

    export let item;
    export let index;

    let className = "";
    export {className as class};


    const {cart} = appStore.get();

    const dispatch = createEventDispatcher();

    let confirmRemoveItem = null;

    function removeItem(e, item)
    {
        $cart.remove(item.id);
        dispatch('remove', item);

        e.preventDefault();
        return false;
    }

    function confirmRemove(e, item)
    {
        confirmRemoveItem = item;
        e.preventDefault();
    }

    function onDocumentClick(e)
    {
        let parent = e.target.closest('.remove-from-cart');
        if (!parent) 
        {
            confirmRemoveItem = null;
        }
    }

</script>

<div class="remove-from-cart {className}">
    <form id={`item-remove-${index}`} method="post" action="api/cart/update">
    <input type="hidden" name="id" value={item.id}>
    <input type="hidden" name="productid" value={item.product.id}>
    <input type="hidden" name="size" value={item.size.id}>
    <input type="hidden" name="action" value="remove">

    <button type="submit" class="trash" on:click={e => confirmRemove(e, item)}>
        <svg xmlns="http://www.w3.org/2000/svg" height="18" width="14"><path fill="currentcolor" d="m1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-12h-12v12zm13-15h-3.5l-1-1h-5l-1 1h-3.5v2h14v-2z"></path></svg>
    </button>
    {#if confirmRemoveItem === item}
        <div class="confirm-remove box">
            <div class="has-text-centered">{$t('cart.confirmRemove')}</div>
            <div class="is-flex">
                <button type="submit" class="button mx-1 mt-2" on:click={e => removeItem(e, item)}>{$t('common.yes')}</button>
                <button type="button" class="button mx-1 mt-2 is-success" on:click={() => confirmRemoveItem = null}>{$t('common.no')}</button>
            </div>
        </div>
    {/if}
    </form>
</div>

<svelte:window on:click={onDocumentClick} />

<style lang="scss">.remove-from-cart {
  position: relative; }

.remove-from-cart button.trash {
  color: #b5b5b5;
  cursor: pointer; }
  .remove-from-cart button.trash:hover {
    color: #4a4a4a; }

.remove-from-cart .confirm-remove {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  z-index: 5; }</style>