<script>

import ShoppingCartModal from '@components/common/ShoppingCartModal.svelte';

import NavMenuSimple from './NavMenuSimple.svelte';
import NavMenuFull from './NavMenuFull.svelte';

export let segment;

$: useSimpleVersion = segment === "checkout";

</script>

<nav id="navbar" class="nav-bar" class:is-full="{!useSimpleVersion}" role="navigation" aria-label="main navigation">
  {#if useSimpleVersion}
    <NavMenuSimple />
  {:else}
    <NavMenuFull />
  {/if}
</nav>
<!-- <div class="fixed-menu-margin" class:is-hidden="{useSimpleVersion}"></div> -->

{#if !useSimpleVersion}
  <ShoppingCartModal/>
{/if}

<style lang="scss">.nav-bar {
  background-color: #3b1e4e;
  color: white; }</style>