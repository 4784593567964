<script>
    import {Formatter, t} from '@utils/Formatter';
    import { appStore } from "@models/Stores";
    import { popupManager} from '@models/PopupManager';
    import { SwiperBehavior } from '@models/SwiperBehavior';

    import CategoryItem from "@components/layout/MainMenuItemCategory.svelte"
    import ContactsList from "@components/contacts/ContactsList.svelte"
    import LangSelection from './LangSelection.svelte';

    let menuController = popupManager.get("mainMenu");
    let menu = popupManager.store("mainMenu");

    const {menus} = appStore.get();
    const mainMenuCategories = $menus.mobileMenuCategories();
    const topMenu = $menus.topMenu();

    function onSwipe(delta)
    {
        if (delta < 0) $menu.close();
    }

    let swiper = new SwiperBehavior(onSwipe, 100);
</script>

<div class="menu-root modal" class:is-active={$menu.isActive}
    on:touchstart|passive={e => swiper.touchStart(e)} 
    on:touchend|passive={e => swiper.touchEnd(e)}>
    <div class="modal-background" on:click={() => $menu.close()}></div>
    <div class="modal-content has-background-white">
        <div class="logo has-background-dark">
            <a class="is-flex" href="{Formatter.url()}" rel=prefetch>
                <img src="/logo.svg" alt="Logo" class="desktop" width="275" height="40">
            </a>
        </div>
        <div class="menu">
            <div class="menu-label">{$t("common.catalog")}</div>
            <ul class="menu-list">
                {#each mainMenuCategories as category}
                    <CategoryItem item={category}/>
                {/each}
            </ul>
            <div class="menu-label">{$t("common.forClients")}</div>
            <ul class="menu-list">
                {#each topMenu as item}
                    {#if !item.isHiddenMobile}
                        <li><a href="{item.url}" target="_self"><span>{item.name}</span></a></li>
                    {/if}
                {/each}
            </ul>
        </div>
        <hr>
        <div class="contacts">
            <div class="columns">
                <div class="column">
                    <ContactsList phones/>
                </div>
                <div class="column">
                    <ContactsList messengers/>
                </div>
                <!-- <p><ContactsList emails/></p> -->
            </div>
            <p>{@html $t("contacts.workHoursShort")}</p>
        </div>
        <LangSelection/>
    </div>
</div>

<style lang="scss">@-webkit-keyframes animation-menu {
  from {
    margin-left: -80%; }
  to {
    margin-left: 0; } }

@keyframes animation-menu {
  from {
    margin-left: -80%; }
  to {
    margin-left: 0; } }

.menu-root {
  align-items: flex-start; }

.modal-content {
  height: 100%;
  max-height: 100%;
  width: 80%;
  max-width: 400px;
  margin: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  animation-direction: reverse;
  -webkit-animation-name: animation-menu;
          animation-name: animation-menu; }
  .modal-content :global(.lang-selection) {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: stretch; }
    .modal-content :global(.lang-selection) :global(a) {
      border-bottom: 1px solid #7e6a8e; }

.modal.is-active .modal-content {
  -webkit-animation-direction: normal;
          animation-direction: normal; }

.logo {
  padding: 0.75rem; }

.menu {
  padding: 0.75rem;
  padding-bottom: 0; }

.contacts {
  padding: 0.75rem; }

hr {
  margin: 0.5rem 0; }</style>