<script>
    import {Formatter} from '@utils/Formatter';
</script>

<a class="is-size-1 is-size-3-mobile logo" href="{ Formatter.url()}" rel=prefetch>
    <img src="/logo.svg" alt="Logo" class="desktop is-hidden-mobile is-marginless-touch" width="275" height="40">
    <img src="/logo-mobile.svg" alt="Logo" aria-hidden="true" class="mobile is-hidden-tablet" width="32" height="32">
</a>


<style lang="scss">.logo {
  display: flex;
  align-items: center;
  padding-left: 0; }
  .logo .desktop {
    max-height: 75%; }</style>