<script>
    import { createEventDispatcher} from 'svelte';

    export let id;
    export let name;
    export let label;
    export let value;
    export let disabled = false;
    export let targetForm = null;
    export let isButton = false;
    export let selectedValue = '';
    export let buttonClass = '';
    export let controlClass = '';

    const dispatch = createEventDispatcher();

    function valueChanged()
    {
        dispatch('change', selectedValue);
    }

    let attrs = {};
    $: attrs.disabled = disabled ? true : undefined;
    $: attrs.checked = selectedValue === value ? true : undefined;

</script>

<div class="control {controlClass}">
    <input type="radio"
           name={name}
           id={id}
           bind:group={selectedValue}
           {value}
           on:change={valueChanged}
           class={isButton? "is-button-radio": "is-checkradio"}
           form={targetForm}
           {...attrs}
    >
    <label for="{id}" class="checkbox-button {buttonClass}" class:button={isButton} {...attrs}>{label}</label>
</div>


<style lang="scss">.is-button-radio {
  display: none;
  transition: border 0.3s;
  transition: color 0.3s; }

.is-button-radio:checked + .checkbox-button {
  color: white;
  background-color: #7e6a8e;
  font-weight: bold; }

.button.checkbox-button {
  padding-left: 1rem !important;
  padding-right: 1rem !important; }</style>