<script>
    import {getPhone, Phones} from '@models/ContactsData';
    import {t, Formatter} from '@utils/Formatter';
    import { popupManager} from '@models/PopupManager';

    import Modal from "@components/common/BoxModal.svelte";
    import ContactsList from "@components/contacts/ContactsList.svelte";
    import WorkHours from "@components/contacts/WorkHours.svelte";
    import ContactUsModal from '@components/contacts/ContactUsModal.svelte';

    const modal = popupManager.get('ContactsModal');
    const callbackModal = popupManager.get('CallbackModal');

    function onCallback()
    {
        callbackModal.open(); 
        modal.close();
    }
    
</script>

<a class="phone-button has-text-white" href={Formatter.url("/contacts")}  on:click|preventDefault={() => modal.open()}>
    <span class="is-dashed">{getPhone(Phones[0])}</span><i class="icon-caret-down is-size-6"></i>
</a>

<Modal id="ContactsModal"  title={$t('contacts.modalTitle')}>
    <div class="mb-4">
        <div class="columns">
            <div class="column"><ContactsList phones itemsClass="is-size-6"/></div>
            <div class="column"><ContactsList messengers emails itemsClass="is-size-6"/></div>
        </div>

        <div class="title is-5 py-2">{$t('contacts.workHours')}</div>
        <div class="work-hours is-size-6 pb-2">
            <WorkHours/>
        </div>
    </div>
    <div class="has-text-centered">
        <button type="button" class="button is-primary" on:click={onCallback}>{$t("contacts.callback")}</button>
    </div>
</Modal>

<ContactUsModal id="CallbackModal"/>

<style lang="scss">:global(#ContactsModal .modal-content) {
  width: 600px; }

.phone-button {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: stretch; }

.icon-caret-down {
  display: flex;
  align-items: center;
  margin-left: 0.25rem; }</style>