<script>
    import ContactsList from "@components/contacts/ContactsList.svelte"
    import {t} from '@utils/Formatter';
    import {appStore} from "@models/Stores"
    import FooterMenuColumn from "./FooterMenuColumn.svelte";

    const {menus} = appStore.get();

    const footerMenus = $menus.footer();
</script>

<footer class="footer has-text-white">
    <div class="container">
        <div class="columns">
            <div class="column">
                <p class="title is-5">{$t("common.contacts")}</p>
                <p><ContactsList phones messengers/></p>
                <br>
                {@html $t("contacts.workHoursShort")}
                <br>
                <p><ContactsList emails/></p>
            </div>
            {#each footerMenus as item}
                <FooterMenuColumn {item}/>
            {/each}
        </div>
        <div class="columns logo">
            <div class="column">
                <img src="/logo.svg" alt="Logo" width="275" height="40">
                <p>{$t("common.copyright", {values: {
                        year: new Date().getFullYear()
                    }})}
                </p>
            </div>
            <div class="column">
                <img src="/logos/visa-mastercard.png" width="150" height="36" alt="Visa Mastercard">
            </div>
        </div>
    </div>
</footer>

<style lang="scss">.title {
  color: white; }

.footer :global(.messenger-component) {
  margin-top: 1.5rem; }

@media screen and (max-width: 768px) {
  .columns.logo {
    margin-top: 2rem; } }</style>