<script>
    
    import ShoppingCartIcon from "@components/layout/ShoppingCartIcon.svelte";
    import CategoryItem from "@components/layout/NavMenuItemCategory.svelte";
    import SearchInput from "@components/layout/SearchInput.svelte";
    import Logo from "@components/layout/Logo.svelte";
    import PhoneButton from './PhoneButton.svelte';
    import NavMenuButton from "./NavMenuButton.svelte";
    import LangSelection from "./LangSelection.svelte";

    import {appStore} from "@models/Stores";

    const {menus} = appStore.get();

    const mainMenuCategories = $menus.mainMenuCategories();
    const topMenu = $menus.topMenu();

</script>

<div class="links-bar is-hidden-touch is-size-7 container">
    {#each topMenu as item}
        {#if !item.isHiddenDesktop}
            <a class="has-text-white" href="{item.url}" target="_self"><span>{item.name}</span></a>
        {/if}
    {/each}
    <LangSelection/>
    <PhoneButton/>
</div>
<div class="menu-bar container">
      <NavMenuButton />
      <Logo />
      {#each mainMenuCategories as category}
        <CategoryItem {category}/>
      {/each}
      <SearchInput/>
      <ShoppingCartIcon/>
</div>

<style lang="scss">.links-bar {
  display: flex;
  margin-top: 0.25rem; }
  .links-bar > :global(*) {
    padding: 0 0.5rem; }
  .links-bar :global(.lang-selection) {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: stretch; }
    .links-bar :global(.lang-selection) :global(a) {
      color: white;
      border-bottom: 1px solid #dbdbdb; }
    .links-bar :global(.lang-selection) :global(span) {
      color: white; }

.menu-bar {
  display: flex;
  position: relative; }
  :global(.menu-bar > *) {
    padding: 0.5rem 0.5rem; }</style>