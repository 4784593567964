<script>
    import { createEventDispatcher } from 'svelte';

    export let value;

    const dispatch = createEventDispatcher();
    const DIGITS_PATTERN = /[\d]+/;
    let currValue = value;

    function updateValue(newValue)
    {
        if (isNaN(newValue)) newValue = currValue;

        value = Math.max(newValue, 1);
        currValue = value;

        dispatch('update', currValue);
    }

    function keyPress(e) {
        if (!DIGITS_PATTERN.test(event.key))
        {
            e.preventDefault();
            return false;
        }
    }

</script>

<div class="field has-addons number-input">
  <div class="control"><button type="button" disabled={value == 1} class="button is-small" on:click={() => updateValue(parseInt(value) - 1)}>-</button></div>
  <div class="control">
    <input class="input is-small" type="number" min="1"
        bind:value={value} 
        on:keypress={keyPress} 
        on:change={() => updateValue(parseInt(value))}>
  </div>
  <div class="control"><button type="button" class="button is-small" on:click={() => updateValue(parseInt(value) + 1)}>+</button></div>
</div>


<style>
    .number-input input::-webkit-outer-spin-button,
    .number-input input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .number-input input[type=number] {
        -moz-appearance: textfield;
        text-align: center;
    }
    .number-input{
        width: 100px;
        margin: auto;
    }</style>