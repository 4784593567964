<script>
    import CategoryIcon from "@components/common/CategoryIcon.svelte";

    export let category = null;

    const categoryImage = category && category.topmenu_image? category.topmenu_image : null;

    function getCategoryImageStyle()
    {
        if (!categoryImage) return '';
        
        return 'background-image: url(' + categoryImage + ')';
    }

    // let isClosed = true;
</script>

{#if category !== null}

<div class="nav-item is-hidden-touch" 
    class:has-dropdown-caret={category.children.length > 0} 
    >
    <!--     on:mouseenter={() => isClosed = false} -->
    <a class="nav-link" href="{category.url}"target="_self">
        <span class="text-with-icon">
            <CategoryIcon class="has-background-white medium" {category}/>
            <span>{category.name}</span>
        </span>
    </a>
    <div class="dropdown-background"></div>
    {#if category.children.length > 0}
        <div class="category-submenu">
            <div class="columns categories-list">
                {#each category.children as childCategory}
                    <div class="column is-narrow px-6" class:no-title={!childCategory.name}>
                        {#if childCategory.name}
                            {#if childCategory.url}
                                <a class="title is-4" href="{childCategory.url}">{childCategory.name}</a>
                            {:else}
                                <div class="title is-4">{childCategory.name}</div>
                            {/if}
                        {/if}
                        <ul>
                            {#each childCategory.children as cat}
                                <li class="text-with-icon">
                                    <CategoryIcon class="medium" category={cat}/>
                                    <a class="category-link" href="{cat.url}" target="_self">{cat.name}</a> <!--   on:click={() => isClosed = true} -->
                                </li>
                            {/each}
                        </ul>
                    </div>
                {/each}
            </div>
            <div class="category-image" style="{getCategoryImageStyle()}"></div>
        </div>
    {/if}
</div>


{/if}

<style lang="scss">.nav-item:hover {
  background-color: #2d173c; }
  .nav-item:hover .category-submenu {
    display: flex; }
  .nav-item:hover.has-dropdown-caret .nav-link > span::after {
    opacity: 1;
    transform: translateY(0); }
  .nav-item:hover.has-dropdown-caret .dropdown-background {
    display: block; }

.nav-item .nav-link > span {
  position: relative; }
  .nav-item .nav-link > span::after {
    position: absolute;
    bottom: -4px;
    left: 15%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px;
    border-color: transparent transparent #fff;
    content: "";
    opacity: 0;
    z-index: 21;
    transition-duration: 0.1;
    transition-property: opacity, transform;
    display: inline-block;
    transform: translateY(5px); }

.category-submenu {
  display: none;
  background-color: white;
  font-size: 0.875rem;
  left: 0;
  min-width: 100%;
  position: absolute;
  z-index: 20;
  overflow: hidden;
  border-radius: 6px;
  border-top: none;
  box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  top: calc(100% + (-4px)); }

.nav-link {
  color: white; }

.category-image {
  height: 100%;
  width: 400px;
  min-height: 400px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position-x: right; }

.categories-list {
  margin: 0.75rem;
  flex-grow: 1;
  justify-content: center; }

.category-link {
  display: flex;
  align-self: stretch;
  align-items: center;
  width: 100%;
  color: #4a4a4a; }
  .category-link:hover {
    color: #7e6a8e; }

.dropdown-background {
  background-color: black;
  opacity: 50%;
  top: 100%;
  left: -100%;
  width: 300%;
  height: 100vh;
  z-index: 19;
  position: absolute;
  display: none;
  pointer-events: none; }

.no-title {
  padding-top: 3rem; }

.nav-link .text-with-icon {
  min-height: 3rem; }</style>