<script>
    export let item;
</script>

<div class="column" class:is-hidden-mobile={item.isHiddenMobile === true} class:is-hidden-desktop={item.isHiddenDesktop === true}>
    {#if item.url !== null}
        <div class="title is-5 mt-3"><a href="{item.url}">{item.name}</a></div>
    {:else}
        <div class="title is-5">{item.name}</div>
    {/if}
    <ul>
        {#each item.children as menu}
            <li class:is-hidden-mobile={item.isHiddenMobile}  class:is-hidden-desktop={item.isHiddenDesktop === true}><a href="{menu.url}" target="_self">{menu.name}</a></li>
        {/each}
    </ul>
</div>

<style lang="scss">.title {
  color: white; }</style>