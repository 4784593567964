<script>
    import {popupManager} from '@models/PopupManager';
    let mainMenu = popupManager.get("mainMenu");
    function openMenu()
    {
        mainMenu.open();
    }
</script>

<button type="button" class="is-size-4 is-hidden-desktop icon-menu" on:click={openMenu}>
</button>

<style lang="scss">button {
  cursor: pointer;
  background-color: transparent;
  color: white;
  border: 0; }
  button:active {
    color: #7e6a8e; }</style>