<script>
    import {Formatter, t} from '@utils/Formatter';
    import ContactsList from "@components/contacts/ContactsList.svelte";

</script>

<div class="menu-bar container is-mobile">
    <a class="logo is-size-1 is-size-2-mobile" href="{ Formatter.url()}" rel=prefetch>
        <img src="/logo.svg" alt="Logo" class="desktop" width="275" height="40">
    </a>
<ContactsList phones combine itemsClass="is-size-7-mobile"/>
<!-- <div class="is-narrow contacts ">
    <ContactsList phones combine itemsClass="is-size-7-mobile"/>
</div> -->
</div>

<style lang="scss">.logo {
  display: flex;
  align-items: center;
  flex-grow: 1; }

.menu-bar {
  display: flex;
  align-items: center;
  padding: 0 0.5rem; }
  .menu-bar :global(.phone-component a) {
    color: white; }
    .menu-bar :global(.phone-component a):hover {
      color: #e6e6e6; }</style>