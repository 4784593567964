<script>
    import {appStore} from "@models/Stores"
    import { popupManager } from '@models/PopupManager';
    import {Formatter} from "@utils/Formatter";
    
    const cart = appStore.get().cart;
    let cartModal = popupManager.get('ShoppingCartModal');

    function openCart()
    {
        if ($cart.items.length !== 0){
            cartModal.open();
        }
    }

</script>

<a class="nav-item is-size-3 is-size-4-mobile" class:is-active={$cart.items.length > 0} href={Formatter.url("/checkout")} on:click|preventDefault={openCart}>
    <i class="icon-shopping-cart"></i>
    {#if $cart.items.length > 0}
        <span class="tag is-rounded is-warning has-text-weight-bold">{$cart.getTotalCount()}</span>
    {/if}
</a>

<style lang="scss">.nav-item {
  display: flex;
  align-self: stretch;
  align-items: center;
  color: white;
  cursor: default; }
  .nav-item.is-active:hover {
    background-color: #2d173c;
    cursor: pointer; }

.tag {
  margin-left: -1.5em;
  margin-top: -2em;
  font-size: 0.4em; }</style>